import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import axios from './config/axios';

const VueTelInputOptions = {
  mode: "international",
  // onlyCountries: ['RU']
}


app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(VueTelInput, VueTelInputOptions); // Define default global options here (optional)
app.provide('$axios', axios);
app.mount('#app');
